import "./App.css";
import alisonHeadshot from "./photos/alison.png";
import benitaHeadshot from "./photos/benita.png";
import dianeHeadshot from "./photos/diane.png";
import jillHeadshot from "./photos/jill.png";
import julieHeadshot from "./photos/julie.png";
import kamanaHeadshot from "./photos/kamana.png";
import natalieHeadshot from "./photos/natalie.png";
import sarahHeadshot from "./photos/sarah.png";

const artistInfo = [
  {
    name: "Diane Roberts",
    bio: "Diane Roberts is an accomplished director, dramaturge, writer and cultural animator, who has collaborated with innovative theatre visionaries and interdisciplinary artists for the past 30 years. She is the founder of the Arrivals Legacy Project (www.arrivalslegacy.com) and has been the lead workshop facilitator for the past 16 years. The roots of storytelling and multi-disciplinary art forms drive her arts practice as a director dramaturg and cultural animator. Her working methodology draws out and establishes a common vocabulary amongst Indigenous and diverse artists, our ways of working and our sense of ourselves as artists in a global society. She is a PhD candidate in Interdisciplinary Studies at Concordia University in Montreal, a 2019 Pierre Elliott Trudeau Scholar and a 2020 Joseph-Armand Bombardier Canada Graduate Scholarship award holder. You can use the attached photo for your collage if not too late to change. The other photo is very old.",
    photo: dianeHeadshot,
  },
  {
    name: "Benita Bailey",
    bio: "Benita Bailey is an Ethiopian-German artist who moved to Montreal in 2016. She has a backround in International Relations and African Studies at the University of Leipzig and at Hong Kong Baptist University & worked in this field in NYC and Beijing as well as research assistant in the Human Rights department at the German parliament in Berlin. From 2012 - 2015 Benita studied acting full-time in Berlin. Since graduating in 2015, Benita has appeared on German Cinema and national television, worked with acclaimed director Robert Wilson and collaborated with theatres in Berlin such as Ballhaus Naunynstraße and Haus der Berliner Festspiele. In Montreal she was part of the renowned BTW Artist’s Mentorship Program (2017/18), worked with Imago Theatre, Scapegoat Theatre among others in venues such as the Rialto, MAI, Centaur Theatre. In 2017, Bailey was part of the cast of six Black actresses for a production of Shange’s ‘For Colored Girls’ at McGill University marking an historical occasion - the institution's first ever all-Black production. She was part of Théâtre La Chapelle’s 2019/2020 season with 'chattermarks'. Benita is currently part of the MAI Alliance Program 2019/20 where she together with a newly found collective called Diaspora Kidz started to develop a new performance piece. She is a part of the Afro-German artist collective, 'Label Noir' with whom she performed in 2020 at Maxim Gorki Theatre Berlin and she created her own show called #yellit From The Mountain where she has conversations with Black Performance Artists from Canada and Germany.",
    photo: benitaHeadshot,
  },
  {
    name: "Kamana Ntibarikure",
    bio: "Kamana Ntibarikure is a multidisciplinary artist with a soft spot for musical theatre.  She enjoys incorporating movement and music into her storytelling as it allows her to delve deeper into her creativity and better bridge the gaps between language, culture and perceptions. She has portrayed the lead role of Sister Mary Clarence in the famous Sister Act musical, headlining the Just for Laughs Festival. She also curated the 8th edition of the Next Wave Festival, a festival that showcases new musical theatre. Her recent directing credits include Manman La Mer (Théâtre Catapulte) which was chosen to represent Canada in Abidjan for the 11th edition of the MASA  (Marché des Arts du Spectacle d’Abidjan), Rendez-Vous with Home (Black Theatre Workshop) for which she was nominated for a META (Montreal English Theatre Awards) in the Oustanding Direction category and a very successful first reading of the French translation of the play Angelique (Centre du Théâtre d’Aujourd’hui), written by Lorena Gale and translated by Mishka Lavigne.",
    photo: kamanaHeadshot,
  },
  {
    name: "Natalie Tin Yin Gan",
    bio: "Natalie Tin Yin Gan (顏婷妍) is an independent choreographer, interdisciplinary artist, and writer working on the unceded ancestral lands of the Coast Salish peoples. Her practice squats at the intersections of food, feminisms, race, voice, and body. She has presented, performed, and spoken in Seattle, Vietnam, Malaysia, Japan, and across Canada. Natalie is the Co-Artistic Director of company Hong Kong Exile. She is a graduate of The Writer’s Studio and completing her teacher certification in Fitzmaurice Voicework®. Natalie is a late sleeper, a late riser, a late bloomer, a latecomer, and a late-night snacker. She publishes writing under a pseudonym.",
    photo: natalieHeadshot,
  },
  {
    name: "Alison Darcy",
    bio: "Alison Darcy works as a director, actor, producer, dramaturge, teacher and is the co-Founder/co-Artistic Director of the award winning, contemporary theatre company Scapegoat Carnivale. Some of her directorial works include: YEV, Blind, Ladies Foursome, Othello, Faust, The Heretics of Bohemia, Hyena Subpoena, Medea, Life is a Dream and Real Estate. She has written, and directed actors on virtual reality projects for Signal Space Labs and co-wrote an adaptation of Goethe’s Faust (books 1&2) and YEV with Joseph Shragge (and designed the set). Selected acting credits include: YEV, Sapientia, Age of Arousal, A Doll House, Bye Bye Baby, Small Returns, girls!girls!girls! and various VR, documentary, cartoon, film and television productions. She has received MECCA, META, and Fringe awards; has worked for the NFB, Blue Metropolis, and FTA; and taught at Bishop’s University, and NTSC (herself an Acting section graduate) among other institutions, as well as freelance.",
    photo: alisonHeadshot,
  },
  {
    name: "Jillian Harris",
    bio: "Jillian Harris is an actor, puppeteer and performance creator based in Tiotia:ke (Montreal). In 2016 she co-founded the performance ensemble Cabal Theatre, producing and performing in four major projects of varying scale and style.They collaborated with local and international artists and played in festivals and unconventional spaces, winning awards and recognition for their original content and striking design. She was last onstage starring in Cabal’s Chattermarks, a new Canadian text co-produced by the Centaur Theatre and Theatre La Chapelle. In 2019 Jillian played a lead role in the indie sci-fi hit Dead Dicks (Postal Code Productions) which was the winner of Fantasia International Film Festival's Best Canadian Feature and has gone on to win numerous awards from festivals around the globe. She was nominated for Best Actress (Vancouver Badass Film Festival), and was the shared recipient of Best Ensemble (Other Worlds Film Festival, Texas).Recent film & TV credits include Swept Up By Christmas (Hallmark), The Voyeurs (Amazon Prime), Saint Narcisse (Bruce LaBruce, CBC) and Fatal Vows (IDTV).In 2020 she was the voice of Mint in Ubisoft’s new battle royale video game, HyperScape. Jillian lends her voice regularly to video games, commercial and corporate voice projects.",
    photo: jillHeadshot,
  },
  {
    name: "Julie Tamiko Manning",
    bio: "Julie Tamiko Manning is an award-winning Montreal actor and theatre creator.  Selected acting credits include: Annie in Jean Dit (Théâtre D’Aujourd’hui), Vic in Vic and Flo Saw A Bear (Talisman), Various in Louis Riel: A Comic-Strip Stage Play (RustWerk Refinery), Elena in Butcher (Centaur), Isabella Bird in Top Girls (Segal), Titania in A Midsummer Nights’ Dream (Repercussion), Clarence in Richard III (Metachroma) and Nancy in Oliver! (National Arts Centre).  She is currently working with the French company cie IKB creating Identités, a play about the many facets of Québecois identity. Her first play, Mixie and the Halfbreeds, a play about mixed identity in multiple universes, was co-written with Adrienne Wong and is on the list 49 Plays by Women of Colour: http://the49list.com/. Her second play, The Tashme Project: The Living Archives, co-created with artist, Matt Miwa, is a verbatim one-act about the Japanese Canadian experience around the WWII internment camps, told through the childhood memories of community elders. It toured through Canada in 2019 and is to be published with Playwrights Canada Press next year. Tashme received multiple META (Montreal English Theatre Awards) and Rideau Award (Ottawa) nominations including winning a META for Best Text. She is working on her third play called Mizushobai, about Kiyoko Tanaka-Goto, a Japanese picture bride turned business woman in 1930’s British Columbia. Julie is co-artistic director of Tashme Productions, associate artistic producer of Metachroma Theatre (whose mandate is to address the under-representation of visible minority actors in Canadian theatre), and is currently an acting mentor with Black Theatre Workshop’s Artist Mentorship Program.",
    photo: julieHeadshot,
  },
  {
    name: "Sarah Rossy",
    bio: "Sarah Rossy is a mythical sonic creature of sorts, with a voice that is colourfully chameleonic yet vulnerably human. Bouncing between her hometown of Tio’tia:ke (Montréal), New York City, and Berlin, she combines influences of jazz, experimental music, and interdisciplinary movement practices into her work for a truly unique result. As a composer, she has a keen interest in experiential processes which traverse through a feminist lens and nurture the ecosystems of artistic community. Recent excursions include: studying with Meredith Monk, pursuing voice/movement research at The World is Sound (Berlin), composing for the Montreal Contemporary Music Lab, exploring her ethnic heritage at the Arabic Music Retreat, and attending multiple residencies at Banff Centre for the Arts. Sarah is a recent (and first Canadian) finalist of the Ella Fitzgerald International Voice Competition, and her research has been graciously supported by the Social Science and Humanities Research Council of Canada, Conseil des arts et lettres du Québec, Conseil des arts de Montréal, and the Socan Foundation. She holds both a B.Mus and M.Mus from McGill University, where she was mentored by composers John Hollenbeck and Christine Jensen. In 2019, she released a debut EP, The Conclusion (Florafone Records), and is anticipating the release of a full-length album in 2021.",
    photo: sarahHeadshot,
  },
];

function App() {
  return (
    <div className="App">
      <div className="App-format">
        <header className="App-header">
          <h1 className="hover-title">DIASPORAKIDZ</h1>
          <h1 className="hover-text">interdisciplinary artist collective</h1>
        </header>
        <body>
          <div className="App-body">
            <div className="Photo-gallery">
              {artistInfo.map((artist) => (
                <div>
                  <img
                    className="Artist-photo"
                    src={artist.photo}
                    alt={artist.name}
                  />
                </div>
              ))}
            </div>
            {artistInfo.map((artist) => (
              <div className="Artist-info">
                {/* <img
                  className="Artist-photo"
                  src={artist.photo}
                  alt={artist.name}
                /> */}
                <div className="Artist-info-format">
                  <h2 className="Artist-name">{artist.name}</h2>
                  <p>{artist.bio}</p>
                </div>
              </div>
            ))}
          </div>
          {/* <p className="App-subheader">Page coming soon.</p> */}
        </body>
      </div>
      <footer className="App-footer">
        <div>© 2021 diasporakids</div>
        <div>website by sarah rossy</div>
      </footer>
    </div>
  );
}

export default App;
